.single-full-flex {
	@include displayflex;
	flex-flow: column nowrap;
	justify-content: space-between;
	padding: 30px;
	border: 0.1rem solid $graylight;
	@media(min-width: em($small)) {
		flex-flow: row wrap;
	}
	@media(min-width: em($medium)) {
		@include flex(5);
		margin: 0 0 0 3.5rem;
		/* At Single Vårt Varumärke */
		.single-profilvartvarumarke & {
			// margin: 0;
		}
	}
	.single-item-flex {
		@include flex(0 1 auto);
		@media(min-width: em($small)) {
			@include flex(1);
			margin: 0 3rem 0 0;
		}
		&:nth-of-type(2) {
			@media(min-width: em($small)) {
				@include flex(1.5);
			}
			@media(min-width: em($large)) {
				@include flex(2);
			}
		}
		.info-wrap {
			p {
				margin-bottom: .4rem;
				padding: .4rem 0;
				border-bottom: 1px solid #d5d5d5;
				/* At Single Vårt Varumärke */
				.single-profilvartvarumarke & {
					border-bottom: none;
				}
				&:last-of-type {
					border-bottom: none;
				}
				&.description {
					border-bottom: none;
					margin: 0;
					padding: 0;
				}
			}
		}
		/* Order info below .info-wrap */
		p {
			&.order-info {
				margin-bottom: .4rem;
				padding: .8rem 0;
				border-top: 1px solid #d5d5d5;
			}
		}
	}
	h1 {
		margin-bottom: 1rem;
		@media(min-width: em($xlarge)) {
			font-size: 8rem;
			margin-bottom: 1.2rem;
		}
	}
	input[type="submit"] {
		margin: 1.2rem 0 3rem 0;
	}
}

// Hide Customer message field. Only show the checkbox.
.acf-field-5ab38338bc69d {
	display: none;
}