/* custom grid
––––––––––––––––––––––––––––––––––––––––– */

.pageWrap {
	padding: 0;
}

.container {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	width: 100%;
	max-width: 192rem;
	@media (min-width: em($medium)) {
		padding-left: 3rem;
		padding-right: 3rem;
	}
	.row {
		margin-left: 0;
		margin-right: 0;
		.column {
			float: left;
			padding-left: 0.2rem;
			padding-right: 0.2rem;
			margin-right: -0.2rem;
			margin-left: -0.2rem;
		}
		.full {
			width: 100%;
		}
		.half {
			width: 49%;
		}
		.third {
			width: 33.33333333%;
		}
		.fourth {
			width: 25%;
		}
	}
}

// Sidebar
.sidebar-main-wrap {
	@include displayflex;
	flex-flow: column wrap;
	clear: both;
	.post-type-archive & {
		flex-flow: column wrap;
	}
	.single-profilvartvarumarke & {
		display: block;
	}
	@media(min-width: em($medium)) {
		flex-flow: row wrap;
		.post-type-archive & {
			flex-flow: row nowrap;
		}
		/* Fix Safari */
		// .single-profilvartvarumarke & {
		// 	flex-flow: column wrap;
		// }

	}
}

.flow-width {
	max-width: 100%;
	margin: 0 auto;
	@media(min-width: em($small)) {
		max-width: 50%;
	}
	@media(min-width: em($large)) {
		max-width: 45%;
	}
	@media(min-width: em($xlarge)) {
		max-width: 33%;
	}
}

/* Column layout */

.multi-column-wrap {
	@include displayflex;
	flex-flow: column wrap;
	justify-content: space-between;
	@media(min-width: em($large)) {
		flex-flow: row wrap;
	}
	.multi-column-item {
		@include flex(0 1 auto);
		margin: 0 0 3rem 0;
		&:first-of-type {
			background: #eee;
			padding: 2rem 3rem 3rem 3rem;
		}
		@media(min-width: em($large)) {
			margin: 0;
			&:first-of-type {
				@include flex(0 1 15%);
			}
			&:nth-of-type(2) {
				@include flex(0 1 32%);
			}
			&:last-of-type {
				@include flex(0 1 45%);
			}
			/* If no related article */
			&.wide {
				flex: 0 1 50%;
				margin: 0 auto;
			}
		}
		.related-info {
			margin: 0 0 2rem 0;
			padding: 0 0 1.5rem 0;
			p {
				margin-bottom: 0.4rem;
				padding: 0.4rem 0;
				border-bottom: 1px solid $graylight;
				&:first-of-type {
					padding-top: 0;
				}
				&:last-of-type {
					border-bottom: 0;
				}
			}
		}
	}
}

/* Other */
.max160rem {
	max-width: 160rem;
	margin: 0 auto !important;
}

.max130rem {
	max-width: 130rem;
	margin: 0 auto !important;
}