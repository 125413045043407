// Only show Sizes that were checked when creating Single Profilartikel (this is shown at single-profilartiklar.php).
.single-profilartiklar {
	.acf-field {
		margin: 0 0 0.4rem 0 !important;
	}

	/* Checkbox field from ACF */
	.acf-field-5ab2624016f22 {
		display: none;
	}

	/* Order text beside order btn */
	.order-text {
		display: inline-block;
		margin: 0 0 0 0.6rem;

		@media(max-width: em(400px)) {
			font-size: 0.9rem;
		}

		@media(min-width: em($medium)) {
			margin: 0 0 0 1rem;
		}
	}
}

/* File */
.file-wrap {
	margin: 0 0 2rem 0;

	.heading {
		margin: 0 0 0.5rem 0;
	}

	.single {
		display: block;
		margin: 0 0 1rem 0;
		border-bottom: 0.1rem solid $graylight;
		padding: 0 0 1rem 0;

		&:last-of-type {
			margin: 0;
			padding: 0;
			border-bottom: none;
		}

		a {
			display: inline-block;
			font-size: 1.4rem;
		}

		p {
			margin: 0;
		}

		.file-size {
			font-size: 1.2rem;
			opacity: 0.8;
		}
	}
}

/* Edit artikel */

.edit-article {
	cursor: pointer;
}

#edit-article-wrap {
	opacity: 0;
	visibility: hidden;
	height: 0;
	overflow: hidden;
	@include transition(.2s ease-in-out);

	&.visible {
		opacity: 1;
		visibility: visible;
		width: 100%;
		height: 100%;
		margin: 3rem 0 0 0;
		padding: 0 1.5rem;

		@media(min-width: em($medium)) {
			margin: 4rem 0 0 0;
		}
	}
}

/* New/edit article, split in two halves */

.new-article-wrap {
	@include displayflex;
	flex-flow: column nowrap;
	justify-content: space-between;
	max-width: 160rem;
	margin: 0 auto;

	@media(min-width: em($medium)) {
		flex-flow: row wrap;
	}

	.new-article-item {
		@include flex(0 1 auto);

		@media(min-width: em($medium)) {
			@include flex(1);
			margin: 0 3rem 0 0;
		}

		@media(min-width: em($medium)) {
			&.two {
				margin: 3.1rem 0 0 0;

				.single-profilartiklar &,
				.page-template-page-custom-todo &,
				.page-template-page-ny-leverantor &,
				.page-template-page-nytt-inlagg &,
				.single-profilleverantorer &,
				.single-profilvartvarumarke & {
					margin: inherit;
				}
			}
		}

		label {
			&.title-label {
				font-family: $fontbold;
				margin: 0 0 .6rem 0;

				.single-profilleverantorer & {
					margin: 2.6rem 0 .6rem 0;
				}
			}
		}

		.title-input {
			padding: 3px 5px;
		}
	}
}

.article-variant {
  display: block;
  input, label {
    display: inline-block;
    margin-left: 1rem;
    margin-bottom: 0;
    vertical-align: middle;
  }

  input[type="checkbox"] {
    opacity: 0;
    width: 0.1rem;
    height: 0.1rem;
    padding: 0;
    margin: -0.1rem;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    &:checked {
      + label {
        &:after {
          content: "";
          position: absolute;
          width: 1.2rem;
          height: 1.2rem;
          background: rgba($red, 0.6);
          left: .4rem;
          top: .6rem;
          right: .3rem;
          bottom: .3rem;
        }
      }
    }
    + label {
      position: relative;
      padding: 0 0 0 2.7rem;
      left: -0.2rem;
      display: inline-block;
      &:before {
        content: "";
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
        border: 1px solid rgba($gray, 0.6);
        left: 0;
        top: .2rem;
      }
    }
  }
}
