footer {
    background-color: $red;
    color: white;
    margin: 3rem 0 0 0;
    padding: 3rem 0;
    /* Extend footer on short pages */
    box-shadow: 0 500px 0 500px $red;
    h3 {
        margin: 0 0 1.5rem 0;
        color: #ddd;
        font-size: 2rem;
    }
    p {
        color: #ddd;
    }
    a {
        /* text-decoration: underline; */
        color: $yellow;
        &:hover,
        &:focus,
        &:active {
            color: $yellow;
        }
    }
    /* When menu is open */
    .menu-open & {
        display: none;
    }
    .footer-content-wrap {
        @include displayflex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        @media(min-width: em($small)) {
            flex-flow: row wrap;
        }
        .footer-item {
            @include flex(0 1 auto);
            display: inline-block;
            vertical-align: top;
            margin: 0 0 3rem 0;
            max-width: 50rem;
            @media(min-width: em($small)) {
                margin: 0 5rem 3rem 0;
            }
            &.footer-logo {
                max-width: 10rem;
                vertical-align: top;
                align-self: flex-start;
                @media(min-width: em($small)) {
                    border-right: 0.2rem solid #f37781;
                    padding: 0 5rem 0 0;
                }
            }
            &.form {
                max-width: 29rem;
                margin-left: 0;
                margin-right: 0;
                
                textarea {
                    min-height: 4.6rem;
                    background: #ddd;
                    border: none;
                    color: $dark;
                    margin: 0 0 0.9rem 0;
                }
                input[type="submit"] {
                    background: $reddarkdark;
                    margin: 0;
                    @media(min-width: em($medium)) {
                        float: right;
                    }
                }
                @media(min-width: em($medium)) {
                    margin-left: auto;
                }
            }
        }
        
    }
}