#list-todos {
    .todo-list-wrap {
        clear: both;
        overflow-x: auto;
    }
    table {
        margin-bottom: 1.5rem;
        width: 100%;
        tbody.list {
            position: relative;
            tr {
                @include transition(.15s ease-in-out);
                &:hover {
                    background: #f7f7f7;
                }
            }
        }
        thead {
            tr {
                background: #dfdfdf;
            }
        }
        th {
            text-align: left;
            cursor: pointer;
            &.oppna {
                text-align: center;
            }
        }
        th,
        td {
            padding: 2rem;
        }
        tr {
            border-bottom: 1px solid #e6e6e6;
            &.hide {
                display: none;
            }
            td {
                &.red {
                    color: $red;
                }
                &.oppna {
                    text-align: center;
                }
                &.typ {
                    font-size: 1px;
                    color: transparent;
                    &:before {
                        background-size: 33px 33px;
                        display: inline-block;
                        width: 33px;
                        height: 33px;
                        content: "";
                    }
                    &.To-do {
                        &:before {
                            background-image: url(assets/images/symbols/todo.svg);
                        }
                    }
                    &.Utlåning {
                        &:before {
                            background-image: url(assets/images/symbols/utlaning.svg);
                        }
                    }
                    &.Från.bärgare {
                        &:before {
                            background-image: url(assets/images/symbols/bargare.svg);
                        }
                    }
                }
            }
        } // Fluid line animation, js
        #fluid-line-list {
            position: absolute;
            left: 0;
            width: 10rem;
            height: 1rem;
            background: $red;
            display: none;
            @media (min-width: em($medium)) {
                display: block;
            }
        }
    }
    .no-result {
        display: none;
        font-size: 1.75rem;
        color: #e8330a;
    }
    .small_special_btn {
        width: 33px;
        max-width: 100%;
        height: auto;
        vertical-align: sub;
    }
    /* Create todo btn */
    .todo-top-wrap {
        overflow: hidden;
        margin: 0 0 3rem 0;
        .create-todo {
            display: inline-block;
            margin: 2rem 0 1rem 0;
            @media (min-width: em($large)) {
                float: right;
                margin: 0;
            }
        }
        /* Typ */
        .filter-todos-typ {
            display: block;
            @media (min-width: em($medium)) {
                display: inline-block;
            }
            @media(min-width: em($xlarge)) {
                float: left;
            }
            .filter-item {
                /* font-size: 1.8rem; */
                display: inline-block;
                margin: 0.8rem 4.2rem 0.8rem 0;
                vertical-align: middle;
                img,
                label {
                    display: inline-block;
                    vertical-align: text-top;
                }
                img {
                    width: 3.1rem;
                    max-width: 100%;
                    vertical-align: middle;
                }
                label {
                    margin: 0.2rem 0.8rem 0 0.2rem;
                    line-height: 1.35;
                    position: relative;
                    input {
                        @include transform(scale(1.2) translateY(-0.1rem));
                        margin: 0 0 0 0.4rem;
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                    }
                    /* Custom checkbox */
                    .checkmark {
                        position: absolute;
                        top: -0.2rem;
                        right: -3.2rem;
                        height: 2.5rem;
                        width: 2.5rem;
                        background-color: #eee;
                        @include transition(.1s ease-in-out);
                    }
                    &:hover input~.checkmark {
                        background-color: #ccc;
                    }
                    input:checked~.checkmark {
                        background-color: $red;
                    }
                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                    input:checked~.checkmark:after {
                        display: block;
                    }
                    .checkmark:after {
                        left: 0.8rem;
                        top: 0.4rem;
                        width: 0.5rem;
                        height: 1rem;
                        border: solid white;
                        border-width: 0 0.3rem 0.3rem 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
    /* Status */
    .filter-todos-status {
        @media (min-width: em($large)) {
            float: left;
            margin: 0 1.8rem 0 0;
            padding: 0 1.8rem 0 0;
            border-right: 1px solid $graylight;
        }
        .filter-item {
            display: inline-block;
            margin: 0.45rem .3rem .35rem 0;
            &.active {
                label {
                    background: $red;
                }
            }
            &.kalender.active {
                label {
                    background: $blue;
                }
            }
            input {
                display: none;
            }
            label {
                display: inline-block;
                cursor: pointer;
                color: white;
                background: $graydark;
            }
        }
    }
    .list-filters {
        overflow: hidden;
        clear: left;
        margin: 0 0 4rem 0;
        padding: 4rem 0 0 0;
        border-top: 1px solid $graylight;
        /* General margin */
        .filter-margin {
            @media(max-width: em($xlarge - 1px)) {
                margin: 0 5rem 4rem 0 !important;
            }
        }
        /* Search */
        .search {
            display: block;
            max-width: 240px;
            width: 100%;
            padding: 1rem;
            font-size: 2rem;
            margin: 0 3rem 0 0;
            @media (min-width: em($large)) {
                display: inline-block;
            }
            @media(min-width: em($xlarge)) {
                float: left;
            }
        }
        /* Date range wrap */
        .date-range-wrap {
            display: block;
            @media (min-width: em($large)) {
                display: inline-block;
            }
            @media(min-width: em($xlarge)) {
                float: right;
            }
            input {
                padding: 1rem;
                font-size: 2rem;
                margin: 0 1rem 0 0;
                max-width: 109px;
            }
            .ui-datepicker-div {
                background: white;
            }
            #filter-dates {
                width: auto;
                line-height: 0;
                padding: 1rem;
                vertical-align: middle;
                margin: -6px 0 0 0;
                height: 3rem;
                color: $dark;
                background: white;
                border: 1px solid $gray;
                font-size: 1.2rem;
            }
            #reset-date {
                cursor: pointer;
                width: 14px;
                height: auto;
                margin: -0.6rem 0 0 0.5rem;
                vertical-align: middle;
            }
            .filter-dates-wrap {
                display: inline-block;
                @media(max-width: em($small)) {
                    margin: 2rem 0 0 0;
                }
            }
        }
        /* User select dropdown */
        .user-dropdown {
            display: block;
            max-width: 240px;
            height: 51px;
            font-size: 2rem;
            margin: 0 3rem 0 0;
            padding: 0.9rem 1.2rem 1rem 1.2rem;
            @media (min-width: em($large)) {
                display: inline-block;
            }
            @media(min-width: em($xlarge)) {
                float: right;
            }
        }
    }
}

/* Fade right side of the table */
.table-fade {
    position: absolute;
    z-index: 2;
    right: 1.5rem;
    width: 3.5rem;
    height: 100%;
    background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF);
    background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF);
    background-image: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF);
    background-image: -o-linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF);
    @media (min-width: em($medium)) {
        display: none;
    }
}