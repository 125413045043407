.four04-wrap {
    padding: 0 1.5rem;
    @media(min-width: em($medium)) {
        margin: 0 0 8rem 0;
    }
    h1 {
        @media(min-width: em($medium)) {
            margin: 0 0 3.1rem 0;
        }
    }
    p {
        @media(min-width: em($medium)) {
            font-size: 1.9rem;
        }
    }
}