.marknad-full-flex {
	@include displayflex;
	flex-flow: column nowrap;
	justify-content: space-between;
	@media(min-width: em($small)) {
		flex-flow: row wrap;
	}
	@media(min-width: em($medium)) {
		@include flex(5);
		margin: 0 0 0 3.5rem;
	}
	.marknad-item-flex {
		@include flex(0 1 auto);
		@media(min-width: em($small)) {
			@include flex(1);
			margin: 0 3rem 0 0;
		}
	}
	h1 {
		margin-bottom: 1rem;
		// @media(min-width: em($xlarge)) {
		// 	font-size: 8rem;
		// 	margin-bottom: 1.2rem;
		// }
	}
}

.colorboxes-full-flex {
	@include displayflex;
    // flex-flow: column nowrap;
    flex-flow: row wrap;
    margin-top: 3rem;
    justify-content: space-between;
    width: 100%;
    &:after {
        content: "";
        @media(min-width: em($small)) {
            flex: 0 1 24%;
        }
    }

	@media(min-width: em($medium)) {
		@include flex(5);
	}
	.colorboxes-item-flex {
        @include flex(0 1 auto);
        margin-bottom: 1.5rem;
        flex: 0 1 46%;

        @media(min-width: em($small)) {
            flex: 0 1 24%;
        }
	}
}

// FÄRGBOXAR

.colorbox {
    width:100%;
    background-size:contain;
    position:relative;
    padding-bottom:100%;
    margin-bottom: 1rem;
    border: 0.1rem solid #e1e1e1;
}
.colorbox span {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    display:block;
}

.bilder-full-flex {
	@include displayflex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    &:after {
        content: "";
        @media(min-width: em($small)) {
            flex: 0 1 32%;
        }
    }
    
        .bilder-item-flex {
        flex: 0 1 100%;
        border: 0.1rem solid $graylight;
        margin-bottom: 1.5rem;
        justify-content: space-between;
        .content {
            padding: 2rem;
            .inner {
                padding: 0;
            }
        }
        @media(min-width: em($small)) {
        flex: 0 1 32%;
        }

    }
}

ul.nedladdningsbara_filer {
    margin: 0;
}

ul.bilder-full-flex, 
ul.nedladdningsbara_filer,
ul.colorboxes-full-flex {
    
    list-style: none;
    a {
        color: inherit;
    }
    li {
        margin: 0;
    }
}



.bilder_wrapper {
    margin-bottom: 2.2rem;
}

img.nedladdningsbild {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    display: block;
}

.link {
    svg {
        height: 1.5rem;
        margin-right: 0.25rem;
    }
    &.uppercase {
        text-transform: uppercase;
    }
    &:hover {
        color: $red;
        fill: $red;
        cursor: pointer;
    }
}

p.inledande_beskrivning {
    margin-bottom: 1.5rem;
    font-size: 125%;
    font-family: $fontlight;
}