// Archive pages
.topbanner-wrap-frontpage {
	@include displayflex;
	flex-flow: column wrap;
	background: $red;
	color: white;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	margin-bottom: 3.5rem;
	@media(min-width: em($medium)) {
		flex-flow: row nowrap;
	}
	.topbanner-image {
		@include flex(1 1 45%); // flex-basis: 49%;
		min-height: 30rem;
		display: block;
		width: 100%;
		background-size: cover;
		background-position: center center;
	}
	.textblock {
		@include flex(1 1 55%); // flex-basis: 49%;
		padding: 3.9rem 5rem;
		h1 {
			font-size: 4rem;
			font-family: $fontlight;
			padding-bottom: 0.5rem;
			margin: 0 0 1.5rem 0;
		}
		p.toptext {
			font-size: 120%;
			font-family: $fontlightitalic;
			padding-bottom: 1rem;
			border-bottom: 0.1rem solid white;
		}
		.button {
			background: white;
			color: $red;
			padding: 1.1rem 2.9rem;
			margin-top: 1rem;
			&:hover,
			&:focus,
			&:active {
				cursor: pointer;
				background: darken(white, 10%);
			}
		}
	}
}

.bigArrow {
	position: relative;
	display: inline-block;
	margin-left: 0.5rem;
	fill: $red;
	width: 2.5rem;
	@media (min-width: em($medium)) {
		width: 3rem;
	}
}

h2.frontPage {
	font-size: 3rem;
	border-bottom: 0.1rem solid $graylight;
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
	@media (min-width: em($medium)) {
		font-size: 3.4rem;
	}
}

h4.frontpageBoxheader {
	border-bottom: 0.1rem solid $gray;
	padding-bottom: 1.5rem;
}

ul.items-full-wrap {
	margin-top: 10rem;
	list-style: none !important;
	li {
		width: 100%;
		@media (min-width: em($medium)) {
			width: 32%;
		}
	}
}

.invalid-login-request-message {
	display: block;
	text-align: center;
	color: $red;
	padding: 2rem 0;
}
