/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */

input,
textarea,
select,
fieldset {
	margin-bottom: 1.5rem;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
	width: 100%;
	padding: 1.5rem;
	/* The .6rem vertically centers text on FF, ignored by Webkit */
	background-color: #fff;
	border: 0.1rem solid #dcdcdc; // border-radius: 0.6rem;
	box-shadow: none;
	box-sizing: border-box;

	.form-failed & {
		border: .1rem solid $red;
	}
}

/* ACF Select */
.select2-container--default .select2-selection {
	border: 0.1rem solid #dcdcdc;
}

/* ACF Select search field */
.select2-search__field {
	margin: auto !important;
}

// Login form
#loginform,
#member-login-form {

	label {
		font-family: $fontbold;
		margin: 0 0 1rem 0;
		color: $text;
	}

	p {
		margin-bottom: 0.7rem;
  }

  input[type=text] {
    font-family: $fontbold;
  }

	@media(min-width: em($small)) {

		#user,
		#pass {
			max-width: 43rem;
		}
  }

  .login-submit {
    .button-primary {
      width: 100%;
      padding: 1.4rem 1.4rem;
      font-size: 1.5rem;
      margin-top: 2rem;
      @media(min-width: em($small)) {
        margin-top: 2.5rem;
        padding: 1.4rem 1.4rem;
      }
    }
  }
}

.forgot-password {
  margin: 3rem 0 3rem 0;
  width: 100%;
  display: inline-block;
	font-size: 1.4rem;
}

.loginerror {
	display: block;
	margin: 0.8rem 0 5rem 0;
	color: $red;
	display: none;
	font-size: 2rem;

	&.form-failed {
		display: block;
	}
}

.acf-form-fields {
	margin-top: 2.6rem;
}

.acf-fields {
	border: none !important;
}

/* Radio */
ul.acf-checkbox-list li input[type="radio"] {
	margin: -0.3rem 0.2rem 0 0;
}

/* Label */
.acf-label {
	margin: 0 0 0.6rem 0 !important;

	&.choose-author {
		margin-top: 1.7rem !important;
	}

	label {
		display: inline-block !important;
		font-family: $fontbold;
		margin: 0 0.3rem 0 0 !important;
		padding: 0;
	}

	p {
		&.description {
			display: inline-block;
			font-style: normal;
		}
	}
}

/* Login form styling */
.loginform-wrapper {
  display: block;
  padding-top: 20vh;
  margin: 0 auto;
  padding: 2rem 2rem 0 2rem;
  max-width: 43rem;
  background: white;
  @include border-radius(2rem);
  @include box-shadow(0, 0, 2rem, rgba(0, 0, 0, .1));

  @media(min-width: em($small)) {
    padding: 5rem 5rem 0 5rem;
  }

  .logo-login-form {
    img {
      width: 23rem;
      @media(min-width: em($small)) {
        width: 33rem;
      }
    }
    p {
      font-style: normal;
      text-transform: uppercase;
      color: $dark;
      padding-bottom: 1rem;
      @media(min-width: em($small)) {
        padding-bottom: 3rem;
      }

    }
  }

  .dekor {
    display: block;
    width: 50%;
    float: right;
    margin-top: -1rem;
    padding: 0;
    height: 1rem;
    .first-dekor {
      float: left;
      width: 55%;
      height: 1rem;
      padding: 0;
      background-color: red;
    }
    .second-dekor {
      float: left;
      width: 15%;
      height: 1rem;
      padding: 0;
      background-color: $gray;
    }
    .third-dekor {
      float: left;
      width: 30%;
      height: 1rem;
      padding: 0;
      background-color: $yellow;
    }
  }
}

/* Taxonomy Status field */
.acf-field-5acf53178521c {
	ul {
		background: #eee;
	}

	.categorychecklist-holder {
		border: none;
	}
}

/* Status list */
.acf-checkbox-list {
	padding: 0.4rem 0.9rem;

	li {
		display: inline-block;
		margin: 0 1.2rem 0 0 !important;
	}

	label {
		vertical-align: text-bottom;
	}
}

/* ACF Title */
.title-input {
	margin: 0;
}

.acf-field {
	margin: 0 0 1.7rem 0 !important;

	.page-template-page-ny-leverantor &,
	.page-template-page-ny-artikel &,
	.page-template-page-custom-todo & {
		margin: 0 0 0.4rem 0 !important;
	}

	input[type="text"],
	input[type="password"],
	input[type="number"],
	input[type="search"],
	input[type="email"],
	input[type="url"],
	textarea,
	select {
		margin: 0 0 1.7rem 0;
	}

	/* Post object */
	&.acf-field-post-object {
		margin: 0 0 1.7rem 0 !important;
	}

	/* Field group */
	&.acf-field-group {
		border: 1px solid #e3e3e3 !important;
		margin: 0 0 2.4rem 0 !important;
		padding: 1.5rem 2.1rem !important;
	}

	.acf-fields>& {
		border-top: none;
		padding: 0;
	}

	/* Required */
	.acf-required {
		display: none;
	}

	/* LABEL */
	/* Antal */
	&.acf-field-5ab3aa483c51a,
	/* Date */
	&.acf-field-5ace1cfbc00ab,
	/* Fyll i detta */
	&.acf-field-5ab3824c51201 {
		label {
			&:after {
				content: ':';
			}
		}
	}

	/* Specific fields */
	&.acf-field-5ab3823751200,
	&.acf-field-5ab38338bc69c,
	&.acf-field-5ab383c8bc69f,
	&.acf-field-5acf0095a782b {
		.acf-label {
			display: none;
		}
	}

	/* Link button Artikel */
	&.acf-field-5acf0095a782b {
		margin: 1.7rem 0 !important;
	}

	/* Specific fields */
	&.acf-field-taxonomy,
	&.acf-field-repeater {
		margin: 0 0 1.7rem 0 !important;
	}
}

/* Removes awkward default styles on some inputs for iOS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

textarea {
	min-height: 4.6rem;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
	border: 0.1rem solid #a1a1a1;
	outline: 0;
}

label,
legend {
	display: block; // margin-bottom: 0.5rem;
	// margin-top: 2rem;
}

fieldset {
	padding: 0;
	border-width: 0;
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
}

label>.label-body {
	display: inline-block;
	margin-left: 0.5rem;
	font-weight: normal;
}

/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
	// display: inline-block;
	// height: 3.8rem;
	// padding: 0 3.0rem;
	// color: #555;
	// text-align: center;
	// font-size: 1.1rem;
	// font-weight: 600;
	// line-height: 3.8rem;
	// letter-spacing: 0.1rem;
	// text-transform: uppercase;
	// text-decoration: none;
	// white-space: nowrap;
	// background-color: transparent;
	// border-radius: 0.4rem;
	// border: 0.1rem solid #bbb;
	// cursor: pointer;
	// box-sizing: border-box;
	@extend .rounded-btn;
	@extend .red-bg;
}

.edit-article-form {
	input[type="submit"] {
		@extend .rounded-btn;
		@extend .red-bg;
		@extend .bigger;
	}
}

// .button:hover,
// button:hover,
// input[type="submit"]:hover,
// input[type="reset"]:hover,
// input[type="button"]:hover,
// .button:focus,
// button:focus,
// input[type="submit"]:focus,
// input[type="reset"]:focus,
// input[type="button"]:focus {
//   color: #333;
//   border-color: #888;
//   outline: 0;
// }
// .button.button-primary,
// button.button-primary,
// input[type="submit"].button-primary,
// input[type="reset"].button-primary,
// input[type="button"].button-primary {
//   color: $dark;
//   background-color: white;
//   border-color: $dark;
// }
// .button.button-primary:hover,
// button.button-primary:hover,
// input[type="submit"].button-primary:hover,
// input[type="reset"].button-primary:hover,
// input[type="button"].button-primary:hover,
// .button.button-primary:focus,
// button.button-primary:focus,
// input[type="submit"].button-primary:focus,
// input[type="reset"].button-primary:focus,
// input[type="button"].button-primary:focus {
//   color: $dark;
//   background-color: white;
//   border-color: $dark;
// }

input[type="url"] {
	color: $text;
}
