.page-template-page-kalender,
.page-template-page-kalender-edit {
	/* Submit btn */
	.acf-form-submit {
		margin: 3rem 0 0 0;
	}
	/* H1 on edit page */
	.h1-edit {
		margin: 0;
	}
	/* Small text */
	.small-text {
		margin: 0 0 4rem 0;
		display: inline-block;
	}
	/* Editable calendar wrap */
	.calendar-admin-wrap {
		width: 100%;
		overflow-x: auto;
		input[type="text"] {
			margin: 0;
		}
		th {
			font-size: 1.2rem;
			font-family: $fontbold;
		}
		/* Hide true/false */
		.acf-field-5b16404ac54f6 {
			position: absolute;
			bottom: 3.3rem;
			font-size: 1.2rem;
			opacity: 0.3;
			@include transition(.15s ease-in-out);
			&:hover,
			&:focus,
			&:active {
				opacity: 1;
			}
			.acf-label,
			.acf-input {
				display: inline-block;
				label {
					font-family: $fontlightitalic;
				}
			}
		}
		.acf-table {
			background: none;
			border: none !important;
			td,
			th {
				border: none !important;
			}
		}
		/* Hide New row btn */
		.acf-fields {
			padding: 2rem 2rem 5.5rem 2rem !important;
			.acf-actions {
				float: right;
				.acf-button {
					font-size: 1rem;
					padding: 0.5rem 0.8rem;
					background: $graylight;
					color: $text;
				}
			}
		}
		.acf-form-fields {
			padding: 0 !important;
		}
		/* Top label */
		.acf-field-5b15281b97407>.acf-label {
			display: none;
		}
		/* Month */
		.acf-field-5b152a2cb7546 {
			margin: 0 0 1.5rem 0 !important;
			.acf-label,
			.acf-input {
				display: inline-block;
			}
			.acf-label {
				font-size: 1.2rem;
				vertical-align: baseline;
			}
		}
		/* Event */
		.acf-field-5b15283297408 {
			.acf-label {
				display: none;
			}
		}
	}
	/* Non editable calendar wrap */
	.calendar-public-wrap {
		h2 {
			border-bottom: 0.2rem solid $dark;
			padding: 0 0 1.8rem 0;
			margin: 6rem 0 2rem 0;
			&:first-of-type {
				margin: 0 0 2rem 0;
			}
		}
		p {
			display: block;
			margin: 0 3rem 0 0;
			@media(min-width: em($small)) {
				display: inline-block;
			}
			&.date-start,
			&.date-end {
				margin: 0;
			}
			&.date-start,
			&.date-end,
			&.date-days {
				display: inline-block;
			}
			&.note {
				display: block;
				margin: 0.4rem 0 0 0;
			}
		}
		.event-outer-wrap {
			margin: 0 0 3rem 0;
		}
		.event-inner-wrap {
			margin: 0 0 2rem 0;
			padding: 0 0 2rem 0;
			border-bottom: 0.1rem solid $gray;
		}
	}
}