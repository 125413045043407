// helpers mixins
// * use * - @extend %listreset;
%listreset {
  list-style: none;
  margin: 0;
  padding: 0;
}

// clearfix
// * use * - @extend %clearfix;
%clearfix {
  &:after {
      content: '';
      display: table;
      clear: both;
  }
}

// animation Use: @include animation(btn-case-ani 8s cubic-bezier(.49, .01, .55, .99) 3s infinite);
@mixin animation($args...) {
  -webkit-animation: $args;
  -moz-animation: $args;
  -o-animation: $args;
  -ms-animation: $args;
  animation: $args;
}

// transition Use: @include transition(.5s ease-in-out);
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// transform Use: @include transform(translateY(-50%));
@mixin transform($args...) {
  -moz-transform: $args;
  -o-transform: $args;
  -webkit-transform: $args;
  -ms-transform: $args;
  transform: $args;
}

//box-shadow Use: @include box-shadow(0, 0, 7px, rgba(0, 0, 0, .3));
@mixin box-shadow($top, $left, $blur, $color, $inset: "") {
  -webkit-box-shadow: $top $left $blur $color #{$inset};
  -moz-box-shadow: $top $left $blur $color #{$inset};
  box-shadow: $top $left $blur $color #{$inset};
}

// border-radius Use; @include border-radius(10px); }
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// Flexbox. @include displayflex;
@mixin displayflex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Flex shorthand values. @include flex(1);
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex order. @include order(1);
@mixin order($val) {
  -webkit-box-ordinal-group: $val;  
  -moz-box-ordinal-group: $val;     
  -ms-flex-order: $val;     
  -webkit-order: $val;  
  order: $val;
}