/* responsive video
––––––––––––––––––––––––––––––––––––––––––––––––– */

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 2.5rem;
    height: 0;
    margin-bottom: 3.0rem;  
  video {
    width: 100%    !important;
    height: auto   !important;
  }    
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}