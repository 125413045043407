/* import fonts
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans 300';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/OpenSans/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('assets/fonts/OpenSans/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/OpenSans/open-sans-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-300.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/OpenSans/open-sans-v15-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans 300 Italic';
  // font-style: italic;
  font-weight: 300;
  src: url('assets/fonts/OpenSans/open-sans-v15-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
       url('assets/fonts/OpenSans/open-sans-v15-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/OpenSans/open-sans-v15-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/OpenSans/open-sans-v15-latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans 400 Italic';
  // font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/OpenSans/open-sans-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
       url('assets/fonts/OpenSans/open-sans-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/OpenSans/open-sans-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/OpenSans/open-sans-v15-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans 400';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/OpenSans/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('assets/fonts/OpenSans/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/OpenSans/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/OpenSans/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans 700';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/OpenSans/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('assets/fonts/OpenSans/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/OpenSans/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/OpenSans/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans 700 Italic';
  // font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/OpenSans/open-sans-v15-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
       url('assets/fonts/OpenSans/open-sans-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/OpenSans/open-sans-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/OpenSans/open-sans-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/OpenSans/open-sans-v15-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* Fonts styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* NOTE
html is set to 62.5% so that all the REM measurements throughout scss are based on 10px sizing. 
So basically 1.5rem = 15px */

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: normal;
  font-family: $fontregular, "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $text;
  background: white;
  opacity: 0;
  @include transition(.2s ease-in-out);
  &.site-loaded {
    opacity: 1;
    background: $graylight;
  }
  &.logged-in {
    background: white;
  }
  /* Menu is open */
  &.menu-open {
    overflow: hidden;
  }
  @media (min-width: em($medium)) {
    font-size: 1.6rem;
  }

}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: $fontbold;
}

h1 {
  font-size: 3.0rem;
  line-height: 1.2;
  margin: 0 0 1.5rem 0;
  @media(min-width: em($small)) {
    margin: 0 0 2rem 0;
  }
  @media (min-width: em($medium)) {
    font-size: 5.0rem;
    margin: 0 0 4rem 0;
    .single-profilartiklar &,
    .page-template-page-custom-todo &,
    .page-template-page-ny-artikel &,
    .page-template-page-ny-leverantor &,
    .single-profilleverantorer & {
      margin: 0 0 3rem 0;
    }
  }
  /* Animation */
  @include animation(ani-heading 0.7s cubic-bezier(.49,
  .01,
  .55,
  .99));
  .site-loaded & {
    @keyframes ani-heading {
      0% {
        opacity: 0;
        @include transform(translateY(1.2rem));
      }
      33% {
        opacity: 1;
      }
      100% {
        @include transform(translateY(0));
      }
    }
    @-webkit-keyframes ani-heading {
      0% {
        opacity: 0;
        @include transform(translateY(1rem));
      }
      33% {
        opacity: 1;
      }
      100% {
        @include transform(translateY(0));
      }
    }
  }
}

h2 {
  font-size: 3rem;
  line-height: 1.25;
  @media (min-width: em($medium)) {
    font-size: 4rem;
  }
}

h3 {
  font-size: 2rem;
  line-height: 1.1;
  @media (min-width: em($medium)) {
    font-size: 2.5rem;
  }
}

h4 {
  font-size: 2.0rem;
  line-height: 1.35;
  @media (min-width: em($medium)) {
    font-size: 2.0rem;
  }
}

h5 {
  font-size: 1.8rem;
  line-height: 1.5;
  @media (min-width: em($medium)) {
    font-size: 1.7rem;
  }
}

h6 {
  font-size: 1.6rem;
  line-height: 1.6;
  @media (min-width: em($medium)) {
    font-size: 1.5rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

a {
  color: $red;
  text-decoration: none;
  @include transition(.06s ease-in-out);
  &:hover,
  &:focus,
  &:active {
    color: $reddark;
  }
}

b,
strong {
  font-family: $fontbold, "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

blockquote {
  display: block;
  font-size: 3rem;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 4.0rem;
  -webkit-margin-end: 4.0rem;
}

.bold-big {
  font-family: $fontbold !important;
  font-size: 1.7rem !important;
  margin: -0.2rem 0 0.8rem 0;
}

.small-text {
  font-size: 1rem;
  opacity: 0.5;
}

small {
  line-height: 170%;
  display: block; 
}

.dash-after {
  &:after {
    content: ' - ';
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}