.single-profiltodos {
    h1 {
        margin: 0 0 1rem 0;
    }

    .title-wrap {
        border-bottom: 0.5rem solid $gray;
        margin: 0 0 2rem 0;
        padding: 0 0 0.3rem 0;

        h2 {
            font-size: 3rem;
            margin: 0;

            &.related-title {
                display: inline;
            }
        }

        a {
            &.related-article-link {
                display: inline-block;
                font-size: 1rem;
                float: right;
                @include transform(translateY(100%));
                top: 100%;
            }
        }
    }

    /* IMG Carousel */
    .archive-carousel {
        max-width: 23rem;
        max-height: 23rem;

        .flickity-prev-next-button {
            display: none;
        }

        &.is-fullscreen {
            max-width: 100%;
            max-height: 100%;

            .flickity-prev-next-button {
                display: block;
            }
        }

        /* If multiple cells */
        &:not(.has-multiple-cells) {

            .flickity-prev-next-button,
            .flickity-page-dots {
                display: none;
            }
        }
    }

    /* Supplier info */
    .supplier-info {
        font-size: 1.2rem;
        line-height: 1.4;
        margin: 0.4rem 0 0 0;
        opacity: 0.4;
        @include transition(.15s ease-in-out);

        &:hover {
            opacity: 1;
        }

        span {
            &:after {
                content: ', ';
            }

            &:last-of-type {
                &:after {
                    content: none;
                }
            }
        }

        p {
            margin: 0;
        }

        .email-button {
          background: #e30613;
          display: inline-block;
          font-size: 1.2rem;
          padding: .9rem 1.4rem;
          margin-top: 1.5rem;
          border-radius: 13rem;
          color: #fff;
          border: none;
          transition: 60ms ease-in-out;
        }
    }

    /* Specific fields */
    .acf-field {
        margin: 0 0 1.2rem 0 !important;

        &.acf-field-textarea {
            margin: 0 0 0.3rem 0 !important;
        }
    }

    #user_dropdown {
        width: auto;
    }

    /* Calendar from/to. Nytt ämne. Listnotering */
    .acf-field-5acc8a2aa0328,
    .acf-field-5acc8a63a0329,
    .acf-field-5acc89c1a0324,
    .acf-field-5acc89d9a0325 {
        display: inline-block;
        width: 49%;
    }

    /* Choose author wrap */
    .choose-author-wrap {
        margin: 0.8rem 0 0 0;

        .acf-label,
        select {
            display: inline-block;
        }

        .acf-label {
            margin: 0 0.2rem 0 0;

            label {
                font-family: $fontregularitalic;
            }
        }
    }

    /* Inputs */
    .acf-field input[type=email],
    .acf-field input[type=number],
    .acf-field input[type=password],
    .acf-field input[type=search],
    .acf-field input[type=text],
    .acf-field input[type=url],
    .acf-field select,
    .acf-field textarea {
        margin: 0;
    }

    /* Labels */
    .acf-field .acf-label {
        margin: 0 0 .2rem 0;
    }
}
