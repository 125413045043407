.main-carousel,
.archive-carousel {
    .flickity-viewport {
        min-height: auto;
        margin: 0 0 4rem 0;
        @media(min-width: em($small)) {
            margin: 0;
        }
        /* On Archive Artiklar */
        .post-type-archive-profilartiklar &,
        .home &,
        body.category & {
            min-height: auto;
        }
        .carousel-cell {
            width: 100%;
            text-align: center;
            img {
                height: 100%;
                min-height: auto;
                width: auto;
                background: white;
                opacity: 0;
                @include transition(0.3s ease-in-out);
                &.flickity-lazyloaded,
                &.flickity-lazyerror {
                    opacity: 1;
                }
                /* On Archive Artiklar */
                .post-type-archive-profilartiklar &,
                .home &,
                body.category & {
                    width: 100%;
                    min-height: auto;
                }
                /* On Single */
                .single & {
                    height: auto;
                    min-height: auto;
                    width: 100%;
                }
            }
            .fullscreen {
              display: none;
            }
        }
    }
    &.is-fullscreen {
        .flickity-viewport {
            .carousel-cell {
                transform: translateY(-50%);
                top: 50%;
                img {
                    height: auto;
                    width: 100%;
                    max-width: 40%;
                    min-height: 0;
                }
                .thumb {
                  display: none;
                }
                .fullscreen {
                  display: inline;
                }
            }
        }
    }
    /* If multiple cells */
    &:not(.has-multiple-cells) {
        .flickity-prev-next-button,
        .flickity-page-dots {
            display: none;
        }
    }
}

/* BUTTONS */

.flickity-prev-next-button {
    background: none;
    &:hover,
    &:focus,
    &:active {
        background: none;
    }
}

.post-type-archive-profilartiklar,
.post-type-archive-profilmaterial,
.frontpageArticles,
body.category {
    .flickity-prev-next-button {
        display: none;
    }
    .is-fullscreen {
        .flickity-prev-next-button {
            display: block;
        }
    }
}

/* Fullscreen  */

.is-fullscreen {
    .flickity-prev-next-button {
        svg {
            fill: #e7e7e7;
        }
    }
}

.flickity-fullscreen-button {
    left: 10px;
    padding: 0; // .flickity-button-icon {
    //     position: absolute;
    //     width: 28px;
    //     height: 28px;
    //     left: 4px;
    //     top: 4px;
    // }
}

.frontpage-carousel {
    margin-top: 5rem;
    .flickity-viewport {
        min-height: 310px;
        width: 100%;
        .carousel-cell {
            width: 98%;
            text-align: center;
            border: 1px solid $graylight;
            height: 310px;
            margin: 0 1%;
            @media(min-width: em($small)) {
                width: 31%;
                height: auto;
            }
            @media(min-width: em($large)) {
                width: 18%;
            }
            img {
                max-width: 100%;
                max-height: 20rem;
                width: auto;
                margin: 1rem 0 0 0;
                @media(min-width: em($medium)) {
                    max-height: 30rem;
                }
            }
            .content {
                padding: 2rem;
                h3 {
                    margin-bottom: 0.7rem;
                    text-transform: uppercase;
                    border-bottom: 0.1rem solid $graylight;
                    padding-bottom: 1rem;
                    @media(min-width: em($large)) {
                        font-size: 1.7rem;
                    }
                    @media(min-width: em($xlarge)) {
                        font-size: 2rem;
                    }
                }
                p {
                    margin-bottom: 0rem;
                    font-size: 1.4rem;
                    font-family: $fontlight;
                }
            }
        }
    }
}