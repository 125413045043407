// header styles
header {
  margin: 2rem 0 1.1rem 0;

  @media (min-width: em($medium)) {
    margin: 2rem 0 4rem 0;
  }

  .header-top-wrap {
    overflow: hidden;
    margin-bottom: 0.9rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $gray;

    .header-logo-wrap {
      @media (min-width: em($medium)) {
        float: left;
      }

      .logo-header {
        text-decoration: none;

        img {
          width: 268px;
          max-width: 76%;

          @media(min-width: em(400px)) {
            max-width: 100%;
          }
        }

        p {
          color: $dark;
          text-transform: uppercase;
          display: block;
          font-size: 2rem;
          line-height: 1;
          margin: 0 0 1.3rem 0;

          @media (min-width: em($medium)) {
            display: block;
            vertical-align: baseline;
            font-size: 1.7rem;
            margin: -0.1rem 0 0 0; // display: inline-block;
            // vertical-align: baseline;
            // font-size: 3.3rem;
            // margin: 0 0 0 1.5rem;
          }
        }
      }
    }

    .logged-in-header-wrap {
      @media (min-width: em($medium)) {
        min-width: 370px;
        float: right;
      }

      img {
        width: 51px;
        max-width: 100%;
        height: auto;
        float: left;
        margin: 0 1.5rem 0 0;
        border-right: 1px solid $gray;
        padding: 0 1.5rem 0 0;
      }

      p {
        display: inline-block;
        margin: 0;
      }

      .heightFixer {
        float: left;

        &:first-of-type {
          display: none;

          @media (min-width: em($medium)) {
            display: block;
          }
        }

        &:last-of-type {
          float: left;
          font-size: 1.2rem;
        }

        .logout {
          font-size: 1rem;

          a {
            color: $graydark;
          }
        }

        .user-name {
          font-size: 1.3rem;
        }
      }
    }
  }

  /* Hamburger menu */
  .hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
      opacity: 0.7;
    }
  }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;

    &::before,
    &::after {
      width: 40px;
      height: 4px;
      background-color: #000;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &::before,
    &::after {
      content: "";
      display: block;
    }

    &::before {
      top: -10px;
    }

    &::after {
      bottom: -10px;
    }
  }

  .hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }

    &::after {
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  .hamburger--squeeze .hamburger-inner {
    .menu-open & {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  .hamburger--squeeze .hamburger-inner::before {
    .menu-open & {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }
  }

  .hamburger--squeeze .hamburger-inner::after {
    .menu-open & {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  /* Menu toggle btns */
  .menu-toggle {
    position: absolute;
    z-index: 1000;
    top: 1.3rem;
    right: 1.5rem;
    font-family: $fontbold;
    font-size: 2rem;

    @media (min-width: em($medium)) {
      display: none;
    }

    /* Open */
    &.open {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;

      .menu-open & {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
    }

    /* Close */
    &.close {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;

      .menu-open & {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  ul.main-menu {
    position: absolute;
    top: 2.2rem;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    height: 0;
    clear: both;
    margin: 0;
    padding: 8rem 1.5rem 1.5rem 1.5rem;
    list-style: none;
    @include transition(.15s ease-in-out);

    @media (min-width: em($medium)) {
      position: static;
      height: auto;
      opacity: 1;
      visibility: visible;
      padding: 0;
      border-bottom: 10px solid $gray;
    }

    .menu-open & {
      opacity: 1;
      visibility: visible;
      height: 100%;
      background: white;
      top: 0;
      z-index: 999;
    }

    li {
      display: block;
      margin: 0 1.3rem 0 0;
      text-align: center;

      @media (min-width: em($medium)) {
        display: inline-block;
        text-align: left;
      }

      &.active {
        // &:before {
        //     border-bottom: 10px solid red;
        //     content: 'ARTIKLAR';
        //     position: absolute;
        //     padding: 0 0 10px 0;
        //     font-family: $fontbold;
        //     position: absolute;
        // }
      }

      a {
        display: inline-block;
        font-size: 2.6rem;
        padding: 0 0 0.2rem 0;
        text-decoration: none;
        font-family: $fontbold;
        color: $dark;
        text-transform: uppercase;
        @include transition(.1s ease-in-out);

        &:hover,
        &:focus,
        &:active {
          color: $red;
        }

        @media (min-width: em($medium)) {
          font-family: $fontregular;
          font-size: 1.6rem;
          padding: 0 0 0.8rem 0;

          &:hover,
          &:focus,
          &:active {
            color: lighten($dark, 4%);
          }
        }
      }
    }

    /* Fluid line animation, js */
    #fluid-line {
      position: absolute;
      left: 3rem;
      width: 0;
      height: 1rem;
      background: $red;
      display: none;

      @media (min-width: em($medium)) {
        display: block;
      }
    }
  }
}
