// Archive pages
.items-full-wrap-outer {
    @include flex(0 1 auto);
    @media(min-width: em($medium)) {
        @include flex(6);
    }
    @media(min-width: em($large)) {
        @include flex(7);
    }
    .items-full-wrap {
        @include displayflex;
        flex-flow: column nowrap;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        @media(min-width: em($small)) {
            flex-flow: row wrap;
        }
        &:after {
            content: "";
            @media(min-width: em($small)) {
                @include flex(0 1 49%);
            }
            @media(min-width: em($xlarge)) {
                @include flex(0 1 32%);
            }
        }
        .item-wrap {
            @include displayflex;
            position: relative;
            overflow: hidden;
            flex-flow: row nowrap;
            margin: 0 0 3rem 0;
            border: 0.1rem solid $graylight;
            @include flex(0 1 auto);
            opacity: 0;
            @include transition(.3s ease-in-out);
            &.loaded {
                opacity: 1;
            }
            &.frontpage {
                margin-top: 3.5rem;
            }
            /* &:hover {
            border-bottom: 1rem solid $red;
        } */
            @media(min-width: em($small)) {
                @include flex(0 1 49%);
            }
            @media(min-width: em($large)) {
                min-height: 270px;
            }
            @media(min-width: em($xlarge)) {
                @include flex(0 1 32%);
            }
            .item-child {
                position: relative;
                justify-content: center;
                flex-direction: column;
                margin: 1.7rem;
                /* Don't do at this archive page */
                body:not(.post-type-archive-profilleverantorer) & {
                    &:nth-of-type(1) {
                        @include flex(1 1 30%);
                    }
                    &:nth-of-type(2) {
                        @include flex(1 1 69%);
                    }
                }
                &:nth-of-type(2) {
                    border-left: 1px solid $graylight;
                    padding-left: 1.7rem;
                    margin: 1.7rem 1.7rem 1.7rem 0;
                }
                @media(min-width: em($large)) {
                    // Don't do at this archive page
                    body:not(.post-type-archive-profilleverantorer) & {
                        &:nth-of-type(1) {
                            @include flex(1 1 50%);
                        }
                        &:nth-of-type(2) {
                            @include flex(1 1 50%);
                        }
                    }
                }
                img {
                    width: 100%;
                    .post-type-archive-profilleverantorer & {
                        width: 70%;
                        margin: 0 auto;
                    }
                    &.plus-red {
                        position: relative;
                        width: 24px;
                        margin: 1.8rem 0 0 0;
                        @media(min-width: em($large)) {
                            position: absolute;
                            bottom: 21px;
                        }
                    }
                }
                h3 {
                    margin-bottom: 0.7rem;
                }
                p {
                    margin-bottom: 0rem;
                    font-size: 1.4rem;
                    &:last-of-type {
                        margin-top: 0.8rem;
                        font-size: 1.3rem;
                        font-family: $fontregularitalic;
                    }
                }
                /* Absolute news text on frontpage box image */
                .image-news-text {
                    position: absolute;
                    z-index: 1;
                    padding: 0.3rem 0.5rem;
                    line-height: 1;
                    color: white;
                    margin: 0 0 0 50%;
                    background: $yellow;
                    font-size: 2rem;
                    font-family: $fontbold;
                    top: 35%;
                    pointer-events: none;
                    @include transform(translate(-50%, -35%));
                    @media(min-width: em(500px)) {
                        font-size: 2.5rem;
                    }
                    @media(min-width: em($medium)) {
                        font-size: 2rem;
                    }
                    @media(min-width: em($large)) {
                        font-size: 3.2rem;
                        padding: 0.5rem 1.2rem;
                    }
                }
            }
        }
    }
    /* Regular font family on frontpage box */
    .frontpage-bottom-info-box {
        p {
            font-family: $fontregular !important;
        }
    }
}

// Categories sidebar
.categories-sidebar {
    list-style: none;
    margin: 0 0 1rem 0;
    @include flex(0 1 auto);
    @media(min-width: em($medium)) {
        margin: 0 3rem 0 0;
        @include flex(1);
    }
    li {
        display: inline;
        @media(min-width: em($medium)) {
            display: block;
        }
        a {
            font-size: 1.1rem;
            background: $graylight;
            padding: 0.8rem 1.4rem;
            display: inline-block;
            color: $text;
            margin: 0 -0.1rem 0.6rem 0;
            text-decoration: none;
            &:hover,
            &:focus,
            &:active {
                background: $reddark;
            }
            @extend .rounded-btn;
            @media(min-width: em($medium)) {
                display: block;
                margin: 0;
                font-size: 1.3rem;
            }
        }
        &.current-cat {
            a {
                background: $reddark;
                color: white;
            }
        }
    }
}