/* Misc
–––––––––––––––––––––––––––––––––––––––––––––––––– */

// hr
hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-width: 0;
  border-top: 0.1rem solid #E1E1E1;
}

// Code
code {
  padding: .2rem .5rem;
  margin: 0 0.2rem;
  font-size: 90%;
  white-space: nowrap;
  background: #F1F1F1;
  border: 0.1rem solid #E1E1E1;
  border-radius: 0.4rem;
}

pre>code {
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
}

// img
img {
  height: auto;

  &.responsive {
    width: 100%;
  }
}

figure.wp-caption {
  margin: 0;

  img {
    margin: 20px 0 0 0;
  }

  .wp-caption-text {
    font-style: italic;
    font-size: 13px;
    line-height: 1.68;
    margin: 8px 0 30px 0;
  }
}

img:not(.flickity-lazyloaded),
figure.wp-caption {
  @include transition(0.1s ease);

  max-width: 100%;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  user-drag: none;
  -webkit-user-drag: none;

  &.alignnone {
    width: 100% !important;

    img {
      width: 100%;
    }
  }

  &.alignleft {
    float: left;
    width: 50% !important;
    margin: 20px 20px 20px 0;
  }

  &.alignright {
    float: right;
    width: 50% !important;
    margin: 20px 0 20px 20px;
  }

  &.aligncenter {
    width: auto !important;
    max-width: 100%;
    margin: 10px 12px 0 0;
  }
}

// Btn
.rounded-btn {
  display: inline-block;
  font-size: 1.2rem;
  padding: 0.9rem 1.4rem;
  border-radius: 13rem;
  color: white;
  border: none;

  &:hover,
  &:focus,
  &:active {
    color: white;
  }

  @include transition(.06s ease-in-out);

  &.bigger,
  &#wp-submit {
    @media(min-width: em($small)) {
      margin-top: 1rem;
      padding: 1.1rem 1.9rem;
      font-size: 1.6rem;
    }
  }

  &.margin-bottom {
    margin-bottom: 1.7rem;
  }
}

// Color
.red-bg {
  background: $red;

  &:hover,
  &:focus,
  &:active {
    background: $reddark;
  }
}

.red-color {
  color: $red;
}

.white-color {
  color: white;

  &:hover,
  &:focus,
  &:active {
    color: white;
  }
}

// SVG
.svg-container {

  // Arrow right
  &.arrow-right {
    svg {
      width: 11px;
      vertical-align: middle;
      margin: -0.2rem 0 0 0;
      @include transition(.1s ease-in-out);

      a:hover & {
        transform: translateX(0.1rem);
      }

      #arrow_right {
        fill: $red;
      }
    }
  }
}

// General
.block {
  display: block !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-left-up-to-small {
  @media(min-width: em($small)) {
    float: left;
  }
}

.float-right-from-small {
  margin: 0 0 2rem 0;

  @media(min-width: em($small)) {
    float: right;
  }

  @media(min-width: em($medium)) {
    margin: 0;
  }
}

/* Clear both */
.clear-both {
  clear: both;
}

// Blocks
.colored-bottom {
  height: 0;
  bottom: -2rem;
  width: 100%;
  opacity: 0;
  background: $red;
  visibility: hidden;
  position: absolute;
  @include transition(.1s ease-in-out);

  .item-wrap:hover & {
    bottom: 0;
    opacity: 1;
    visibility: visible;
    height: 1rem;
  }
}

/* Hide screen reader text */
.screen-reader-text {
  display: none;
}

/* Uppercase */
.uc {
  text-transform: uppercase;
}
