// Font regular
$fontregular: "Open Sans 400", sans-serif;
$fontregularitalic: "Open Sans 400 Italic", sans-serif;

.font-regular {
    font-family: $fontregular;
}
.font-regular-italic {
    font-family: $fontregularitalic;
}

// Font light
$fontlight: "Open Sans 300", sans-serif;
$fontlightitalic: "Open Sans 300 Italic", sans-serif;

.font-light {
	font-family: $fontlight;
}
.font-light-italic {
    font-family: $fontlightitalic;
}

// Font bold
$fontbold: "Open Sans 700", sans-serif;
$fontbolditalic: "Open Sans 700 Italic", sans-serif;

.font-bold {
    font-family: $fontbold;
}
.font-bold-italic {
    font-family: $fontbolditalic;
}

// Colors
$dark: #1D1D1B;
$text: #353535;
$gray: #cacac8;
$graydark: #8a8a8a;
$graylight: #d5d5d5;
$red: #e30613;
$reddark: #d11f29;
$reddarkdark: #b80d17;
$redlight: #ffc1c6;
$orange: #e8330a;
$yellow: #ffec00;
$blue: #3c82b0;

// Breakpoints
$small: 768px;
$medium: 992px;
$large: 1200px;
$xlarge: 1600px;
$xxlarge: 1920px;