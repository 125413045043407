.meny-burger {
  // display: inline-block;
  display: none;
  float: right;
  margin-top: 6.0rem;
}

/* Icon 1 */
#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 5.0rem;
  height: 2.5rem;
  position: relative;
  // margin: 12.0rem 12.0rem 13.0rem 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 0.3rem;
  width: 100%;
  background: #333;
  // border-radius: 0;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0rem;
}

#nav-icon1 span:nth-child(2) {
  top: 1.2rem;
}

#nav-icon1 span:nth-child(3) {
  top: 2.4rem;
}

#nav-icon1.open span:nth-child(1) {
  top: 1.2rem;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -5.0rem;
}

#nav-icon1.open span:nth-child(3) {
  top: 1.2rem;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
