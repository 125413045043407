.page-template-page-success-redirect,
.error404 {
	/* Header */
	header {
		margin-bottom: 0;
		.main-menu {
			border-bottom: 0;
		}
	}
	/* Content */
	.main-container {
		background: $yellow;
		h1 {
			margin: 0;
			padding: 0 1.5rem;
		}
		.logo {
			max-width: 13rem;
			@media(min-width: em($medium)) {
				max-width: 23rem;
			}
			margin: 4rem 0 0 0;
		}
	}
	.main-content {
		padding: 3rem 0;
		text-align: center;
		margin: 0 auto;
		@media(min-width: em($small)) {
			max-width: 50%;
			padding: 9rem 0 13rem 0;
		}
	}
	/* Footer */
	footer {
		margin: 0;
	}
}